import React from 'react'
import { Link } from 'gatsby'

import { eventsFields } from '../../../config/constants'
import { useAsync, useAsyncEffect } from '../../hooks'
import { fetchEvents } from '../../api'
import { classes } from '../../utils'
import Layout from '../../components/layout'
import Throbber from '../../components/throbber'
import { FormEventsSignup } from '../../components/forms'
import Table from '../../components/table'

import { loading, disabled } from './index.module.sass'

const CentrumOV = ({ location }) => {
  const { value: rows, status, execute } = useAsync(fetchEvents, false)

  useAsyncEffect(execute)

  return (
    <Layout location={location} customClass="enrollment">
      <header>
        <h1>
          Přihlašování na akce
          <br />
          Centra OV
        </h1>
      </header>
      <section className="page-content">
        <div>
          <h2>Aktuálně vypsané akce</h2>
          {status === 'pending' && (
            <div className={loading}>
              <p>Přehled vypsaných akcí se ověřuje...</p>
              <Throbber />
            </div>
          )}
          {status === 'success' ? (
            <Table>
              <thead>
                <tr>
                  <th>{eventsFields.name}</th>
                  <th>{eventsFields.eventDate}</th>
                  <th>{eventsFields.closeDate}</th>
                  <th>Zbývající volná místa</th>
                </tr>
              </thead>
              <tbody>
                {rows.length ? (
                  rows.map((row) => (
                    <tr
                      key={row.id}
                      className={classes(!row.active && disabled)}
                    >
                      <td>{row.name}</td>
                      <td>{row.eventDate}</td>
                      <td>
                        {new Date(row.closeDate).toLocaleDateString('cs-CZ')}
                      </td>
                      <td>
                        {row.active
                          ? row.capacity - row.attendees > 0
                            ? row.capacity - row.attendees
                            : 'Kapacita naplněna'
                          : 'Přihlášky uzavřeny'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>Aktuálně nejsou vypsány žádné akce.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <p>
              Přehled akcí se nepodařilo načíst. Zkuste to znovu za chvilku.
              Pokud problém přetrvá, <Link to="/kontakt">kontaktujte nás</Link>.
            </p>
          )}
        </div>
        <h2>Přihláška</h2>
        <p>Prosím, vyberte akci a vyplňte Vaše údaje či údaje dítěte.</p>
        <p>
          Pokud se účastníte více akcí, odešlete tento formulář zvlášť pro
          každou z nich. Neobdržíte-li do 24h potvrzení o přihlášení,
          kontaktujte <Link to="/kontakt">předsedu odboru</Link>.<br />
        </p>
        <FormEventsSignup events={rows} />
      </section>
    </Layout>
  )
}

export default CentrumOV
